import { createContext, useContext, useEffect, useState } from 'react';
import Script from 'next/script';
import type {
  AirgapAPI,
  PreInitTranscendAPI,
  TranscendAPI,
} from '@transcend-io/airgap.js-types';

/** A stub for `window.airgap` to be used before airgap is actually loaded */
type PreInitAirgapAPI = Required<Pick<AirgapAPI, 'readyQueue' | 'ready'>>;

/**
 * Add `window.airgap` and `window.transcend` to the global namespace
 */
declare global {
  interface Window {
    /** airgap.js interface */
    airgap?: PreInitAirgapAPI | AirgapAPI;
    /** Transcend consent manager interface */
    transcend?: PreInitTranscendAPI | TranscendAPI;
  }
}

/**
 * The `airgap` and `transcend` consent management APIs
 *
 * @example const { airgap, transcend } = useConsentManager();
 */
interface ConsentAPI {
  /** The `airgap` consent management instance */
  airgap?: AirgapAPI;
  /** The `transcend` consent manager UI instance */
  transcend?: TranscendAPI;
}

interface ConsentProviderProps {
  /** The children of this context provider */
  children: React.ReactNode;
  /** The src URL from the Transcend CDN */
  airgapSrc: string;
}

/** The React context by the `useConsentManager()` hook */
export const ConsentContext = createContext<ConsentAPI>({});

/**
 * React context provider for `window.airgap` and `window.transcend`
 * @see https://docs.transcend.io/docs/consent/faq
 */
export const ConsentProvider: React.FC<ConsentProviderProps> = ({
  children,
  airgapSrc,
}) => {
  const [airgap, setAirgap] = useState<AirgapAPI | undefined>();
  const [transcend, setTranscend] = useState<TranscendAPI | undefined>();

  // `useEffect` ensures this is only executed in browser
  useEffect(() => {
    // Stub transcend with PreInit API
    if (!self.transcend?.ready) {
      const preInitTranscend: PreInitTranscendAPI = {
        readyQueue: [],
        ready(callback) {
          this.readyQueue.push(callback);
        },
        ...self.transcend,
      };
      self.transcend = preInitTranscend;
    }

    // Stub airgap with PreInit API
    if (!self.airgap?.ready) {
      const preInitAirgap: PreInitAirgapAPI = {
        readyQueue: [],
        ready(callback) {
          this.readyQueue.push(callback);
        },
        ...self.airgap,
      };
      self.airgap = preInitAirgap;
    }

    // Wait for consent manager UI to load, and set it in the React state
    if (!transcend) {
      self.transcend.ready((transcend) => {
        setTranscend(transcend);
      });
    }

    // Wait for airgap.js core to load, and set it in the React state
    if (!airgap) {
      self.airgap.ready((airgap) => {
        setAirgap(airgap);
      });
    }
  }, [airgap, transcend]);

  return (
    <>
      <Script src={airgapSrc} data-lazy-load-ui="on" />
      <ConsentContext.Provider value={{ airgap, transcend }}>
        {children}
      </ConsentContext.Provider>
    </>
  );
};

export const useConsentManager = (): ConsentAPI => useContext(ConsentContext);
